<template>
  <div>
    <ModalEditOptionEditSettings
      v-if="showEditSettings"
      :showModal="showEditSettings"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="locationId === 0"
      :editFunction="setShowEditSettings"
    >
      <template v-slot:title>Location edit rights</template>
    </ui-section-header>

    <table class="table is-fullwidth">
      <tbody>
        <tr>
          <td>Channels</td>
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="!option.EditSettings.EditChannels"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
              <font-awesome-icon
                v-if="option.EditSettings.EditChannels"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>Image</td>
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="!option.EditSettings.EditImage"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
              <font-awesome-icon
                v-if="option.EditSettings.EditImage"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>Meeting types</td>
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="!option.EditSettings.EditMeetingtypes"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
              <font-awesome-icon
                v-if="option.EditSettings.EditMeetingtypes"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>Prices</td>
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="!option.EditSettings.EditPrice"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
              <font-awesome-icon
                v-if="option.EditSettings.EditPrice"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>Texts</td>
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                :icon="[
                  'fas',
                  option.EditSettings.EditText ? 'check' : 'times',
                ]"
                :class="{
                  'has-text-danger': !option.EditSettings.EditText,
                  'has-text-success': option.EditSettings.EditText,
                }"
              />
            </span>
          </td>
        </tr>
        <tr>
          <td>Features</td>
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="!option.EditSettings.EditFeatures"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
              <font-awesome-icon
                v-if="option.EditSettings.EditFeatures"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    ModalEditOptionEditSettings: () =>
      import('@/components/Options/ModalEditOptionEditSettings'),
  },

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      showEditSettings: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),
  },

  created() {},

  methods: {
    setShowEditSettings() {
      this.showEditSettings = true
    },

    hideModal() {
      this.showEditSettings = false
    },
  },
}
</script>

<style></style>
